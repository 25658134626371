// 这里把事件名统一抽离到funName.js统一管理，方便维护，避免重复。

export const Net_SetCachePage = "Net_SetCachePage"; // 更新缓存子页集合

export const Net_SetChildCaches = "Net_SetChildCaches"; //定义路由中所有需要被缓存的子页面

export const Net_SetPageTitle = "Net_SetPageTitle"; // 更新页面标题

export const Set_User_Info = "Set_User_Info"; // 设置用户信息

export const Temp_DataBus = "Temp_DataBus"; //临时数据保存

export const Refresh_Router = "Refresh_Router"; //需要被更新的路由

export const Net_SetNavToggle = "Net_SetNavToggle"; //更新底部导航显示

export const Net_SetDeviceisLand = "Net_SetDeviceisLand"; //更新屏幕方向

export const Net_SetDeviceWidth = "Net_SetDeviceWidth"; //更新屏幕宽

export const Net_SetDeviceHeight = "Net_SetDeviceHeight"; //更新屏幕高

export const Media_GetCourse = "Media_GetCourse"; //获取课程信息

export const Media_SetCourse = "Media_SetCourse"; //设置课程信息

export const Media_SetSource = "Media_SetSource"; //设置播放资源

export const Media_GetSource = "Media_GetSource"; //得到媒体资源

export const Media_SetSeekTime = "Media_SetSeekTime"; //设置播放时间

export const Media_GetSeekTime = "Media_GetSeekTime"; //获取播放时间

export const Media_SetProgress = "Media_SetProgress"; //设置进度信息

export const Media_GetProgress = "Media_GetProgress"; //获取进度信息

export const Media_PostProgress = "Media_PostProgress"; //提交学习进度

export const Media_UpdatePlayTime = "Media_UpdatePlayTime"; //更新播放时长

export const Media_GetCourseAll = "Media_GetCourseAll"; //获取当前课程所有信息（课程详情、播放进度、播放地址)

export const Media_ChangeVideo = "Media_ChangeVideo"; //更换课件

export const Media_UseMini = "Media_UseMini"; //使用mini播放器

export const Media_MiniHide = "Media_MiniHide"; //迷你播放器是是否隐藏

export const Meida_MiniAutoPlay = "Meida_MiniAutoPlay"; //迷你播放器是否自动播放

export const Media_RollBack = "Media_RollBack"; //播放器回滚

export const Media_MiniPlaying = "Media_MiniPlaying"; //迷你播放器是否正在播放

export const Media_LoadLastStudy = "Media_LoadLastStudy"; //加载最后一次学习的课程

export const Special_tab_name = "Special_tab_name"; //专题tab名称

// ckplayer
export const SET_CURRENT_PLAY_ITEM = "SET_CURRENT_PLAY_ITEM"; //当前播放课件
export const SET_CURRENT_PLAY_TIME = "SET_CURRENT_PLAY_TIME"; //当前播放器播放时间
export const SET_CURRENT_PLAY_PROGRESS = "SET_CURRENT_PLAY_PROGRESS"; //当前播放课件进度
export const SET_CURRENT_PLAY_SEEKTIME = "SET_CURRENT_PLAY_SEEKTIME"; //定位当前播放位置
export const PLAYER_HAS_PLAY = "PLAYER_HAS_PLAY";
export const PLAYER_SET_SCRRENSHOT = "PLAYER_SET_SCRRENSHOT"; //播放器视频截图
export const SET_SHOW_OR_HIDE_PLAYER = "SET_SHOW_OR_HIDE_PLAYER"; //是否显示MINI播放器

//专题配置
export const Set_HomeWork = "Set_HomeWork"; //作业id

// 专题班
export const Set_Special_Info = "Set_Special_Info"; // 获取专题班信息
export const Set_Special_Apply = "Set_Special_Apply"; // 判断是否报名
export const Set_Special_Apply_Info = "Set_Special_Apply_Info"; //获取报名信息
