import * as type from "../funName";

function actionCommonHttp(sourceUrl, params, method = "get") {
  return new Promise(function (resolve, reject) {
    dsf.http[method](sourceUrl, params)
      .done(res => {
        resolve(res);
      })
      .error(err => {
        reject(err);
      });
  });
}

const ckPlayer = {
  playerModel: "normal",
  state: {
    curPlayItem: null,
    svaeProgress: "",
    curPlayTime: "00:00:00",
    curPlayProgress: 0,
    seektime: 0,
    screenshot: null,
    shouMiniPlayer: false,
  },
  mutations: {
    [type.SET_CURRENT_PLAY_TIME](state, data) {
      state.curPlayTime = data;
    },
    [type.SET_CURRENT_PLAY_PROGRESS](state, data) {
      state.curPlayProgress = data;
    },
    [type.SET_CURRENT_PLAY_SEEKTIME](state, data) {
      state.seektime = data;
    },
    [type.SET_CURRENT_PLAY_ITEM](state, data) {
      state.curPlayItem = data;
    },
    [type.PLAYER_HAS_PLAY](state, data) {
      state.svaeProgress = data;
    },
    [type.PLAYER_SET_SCRRENSHOT](state, data) {
      state.screenshot = data;
    },
    [type.SET_SHOW_OR_HIDE_PLAYER](state, data) {
      state.shouMiniPlayer = data;
    },
  },
  actions: {
    playerPlaySaveProgress({ commit }, query) {
      const { courseId, coursewareId, watchPoint, pulseTime, pulseRate } = query;
      const sourceUrl = "nc/course/front/pulseSaveRecord";
      const params = {
        courseId,
        coursewareId,
        watchPoint,
        pulseTime,
        pulseRate,
      };
      actionCommonHttp(sourceUrl, params, "post")
        .then(res => {
          !res.success && dsf.layer.pc.message("保存学习进度失败,请重新登录或联系管理员", false);
          res.success && commit(types.SET_CURRENT_PLAY_PROGRESS, res.data.finishedRate);
        })
        .catch(err => {
          console.error("保存进度失败");
        });
    },
    uploadScreenshot({ commit }, query) {
      const sourceUrl = "file/base64Img";
      const params = {
        base64ImgStr: query.substring(23),
      };
      actionCommonHttp(sourceUrl, params, "post")
        .then(res => {
          res.success && commit(type.PLAYER_SET_SCRRENSHOT, res.data);
        })
        .catch(err => {
          console.error("上传视频截图");
        });
    },
  },
  getters: {
    getCurPlayItem: state => {
      return state.curPlayItem;
    },
    getScreenshot: state => {
      return state.screenshot;
    },
    getCurPlayTime: state => {
      return state.curPlayTime;
    },
    getCurPlayProgress: state => {
      return state.curPlayProgress;
    },
    getSeektime: state => {
      return state.seektime;
    },
    getShouMiniPlayer: state => {
      return state.shouMiniPlayer;
    },
  },
};

export default ckPlayer;
