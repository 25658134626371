import * as type from "../funName";

function actionCommonHttp(sourceUrl, params, method = "get") {
  return new Promise(function (resolve, reject) {
    dsf.http[method](sourceUrl, params, $$webRoot.nc)
      .done(res => {
        resolve(res);
      })
      .error(err => {
        reject(err);
      });
  });
}

const special = {
  state: {
    info: {},
    applyState: null,
    applyInfo: {},
  },
  mutations: {
    [type.Set_Special_Info](state, data) {
      state.info = data;
    },
    [type.Set_Special_Apply](state, data) {
      state.applyState = data.success;
    },
    [type.Set_Special_Apply_Info](state, data) {
      state.applyInfo = data;
    },
  },
  actions: {
    specialInfo({ commit }, query) {
      //专题班信息
      const { id } = query;
      const sourceUrl = "nc/class/info";
      const params = {
        id,
      };
      return new Promise((resolve, reject) => {
        actionCommonHttp(sourceUrl, params, "get")
          .then(res => {
            res.success && commit(type.Set_Special_Info, res.data);
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
            console.error("失败==》", err);
          });
      });
    },
    specialApplyInfo({ commit }, query) {
      // 专题班报名信息
      const { data, id } = query;
      const sourceUrl = "/nc/class/apply/info";
      const params = { id };
      actionCommonHttp(sourceUrl, params, "get")
        .then(res => {
          res.success && commit(type.Set_Special_Apply_Info, res.data);
        })
        .catch(err => {
          console.error(this, "失败==》", err);
        });
    },
    specialApply({ commit }, query) {
      //专题班报名
      const { data, id } = query;
      const sourceUrl = "nc/class/enroll";
      const params = data ? Object.assign({}, data, { id }) : { id };
      actionCommonHttp(sourceUrl, params, "post")
        .then(res => {
          res.success && commit(type.Set_Special_Apply, res);
          dsf.layer.message(res.message);
        })
        .catch(err => {
          console.error(this, "失败==》", err);
        });
    },
  },
  getters: {
    getSpecialInfo: state => {
      return state.info;
    },
    getSpecialApplyState: state => {
      return state.applyState;
    },
    getSpecialApplyInfor: state => {
      return state.applyInfo;
    },
  },
};

export default special;
