import * as type from "../funName";

function actionCommonHttp(sourceUrl, params, method = "get") {
  return new Promise(function (resolve, reject) {
    dsf.http[method](sourceUrl, params)
      .done(res => {
        resolve(res);
      })
      .error(err => {
        reject(err);
      });
  });
}

const assessment = {
  state: {
    homeworkData: null,
  },
  mutations: {
    [type.Set_HomeWork](state, data) {
      state.homeworkData = data;
    },
  },
  actions: {
    assessmentInfo({ commit }, query) {
      const { id } = query;
      const sourceUrl = "nc/class/assessment/info";
      const params = {
        // id,
      };
      actionCommonHttp(sourceUrl, params, "get")
        .then(res => {
          res.success && commit(type.Set_HomeWork, res.data);
        })
        .catch(err => {
          console.error("失败==》", err);
        });
    },
  },
  getters: {
    getHomeWork: state => {
      return state.homeworkData;
    },
  },
};

export default assessment;
