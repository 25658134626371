//注入平台公共接口调用
export default dsf => {
  let addAPI = dsf.api.addAPI;
  //每个函数最后二个参数必须是options和baseUrl，且函数不可以使用箭头函数
  // addAPI("demo", function (ns, params,options, baseUrl) {
  //   let url = "xxx/xxx/xxx"
  //   return this.$http.get(url, params, options,baseUrl);
  // });
  /** 名师 **/

  // 名师列表
  addAPI("famousTeacherList", function (params, baseUrl) {
    let url = "/meta/list/data";
    return this.$http.get(url, params, options, baseUrl);
  });

  // 本周双星
  addAPI("famousTeacherStar", function (params, options, baseUrl) {
    let url = "/nc/szk/ms/findTwoStar";
    return this.$http.get(url, params, options, baseUrl);
  });

  // 名师详情
  addAPI("famousTeacherDetail", function (params, options, baseUrl) {
    let url = "/nc/szk/ms/findMsDetail";
    return this.$http.get(url, params, options, baseUrl);
  });
  // 推荐

  // 名师直播
  addAPI("famousTeacherLive", function (params, options, baseUrl) {
    let url = "/nc/szk/ms/getZblistById";
    return this.$http.get(url, params, options, baseUrl);
  });

  // 获取系列
  addAPI("getSeries", function (params, options, baseUrl) {
    let url = "/yyzx/pdgl/pdglList";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });
  // 推荐头部固定
  addAPI("getRecommend", function (params, options, baseUrl) {
    let url = "nc/zbgl/main/list";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 专题详情
  addAPI("getSpecialDetail", function (params, options, baseUrl) {
    let url = "/yyzx/pdgl/pdid";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 专题详情
  addAPI("getSpecialDetailList", function (params, options, baseUrl) {
    let url = "/yyzx/pdgl/pdlbid";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 根据编号查询频道列表
  addAPI("queryCatalogByCodes", function (params, options, baseUrl) {
    let url = "/cms/catalog/queryCatalogByCodes";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 根据Id查询频道列表
  addAPI("queryCatalogByPId", function (params, options, baseUrl) {
    let url = "/cms/catalog/queryCatalogByPId";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 根据Id查询文章列表
  addAPI("queryArticlesByCatalogCode", function (params, options, baseUrl) {
    let url = "/cms/articles/queryArticlesByCatalogCode";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 根据新闻id查询新闻详情
  addAPI("queryArticlesById", function (params, options, baseUrl) {
    let url = "/cms/articles/queryArticlesById";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 根据频道Id。查询文章列表
  addAPI("queryArticlesByCatalogId", function (params, options, baseUrl) {
    let url = "cms/articles/queryArticlesByCatalogId";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 课堂列表
  addAPI("getClassRoomList", function (params, options, baseUrl) {
    let url = "/meta/list/data";
    return this.$http.post(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 课堂详情
  addAPI("getClassRoomDetail", function (params, options, baseUrl) {
    let url = "/meta/data";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 课堂详情用户签到统计
  addAPI("getClassRoomDetailUserCount", function (params, options, baseUrl) {
    let url = "/idj/user/count";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 直播列表
  addAPI("getLiveVideoList", function (params, options, baseUrl) {
    let url = "/nc/zbgl/getZB";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 直播详情
  addAPI("getLiveVideoDetail", function (params, options, baseUrl) {
    let url = "/nc/zbgl/zbdetail";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 首页轮播
  addAPI("getSellCount", function (params, options, baseUrl) {
    let url = "/nc/lbt/sellByCount";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 查询板块下主题信息
  addAPI("getBbsList", function (params, options, baseUrl) {
    let url = "/bbs/web/topic/list";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 查询投票统计
  addAPI("queryVoteStatisticsInfo", function (params, options, baseUrl) {
    let url = "/vote/basic/queryVoteStatisticsInfo";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });

  // 获取二维码接口
  addAPI("buildQrCodeQd", function (params) {
    let url = "/nc/ncqrcode/buildQrCodeQd";
    return this.$http.get(url, params, null, $$webRoot.dsfa).then(({ data }) => {
      return data;
    });
  });

  // 获取快速投票标题
  addAPI("getVoteTitle", function (params, options, baseUrl) {
    let url = "/idj/meeting/vote/list";
    return this.$http.get(url, params, options, baseUrl).then(({ data }) => {
      return data;
    });
  });
};
