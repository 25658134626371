
import queue from './queue';
import {
  Media_SetSource,
  Media_SetSeekTime,
  Media_SetProgress,
  Media_GetSource,
  Media_GetProgress,
  Media_GetCourse,
  Media_SetCourse,
  Media_PostProgress,
  Media_UpdatePlayTime,
  Media_GetCourseAll,
  Media_ChangeVideo,
  Media_UseMini,
  Media_MiniHide,
  Meida_MiniAutoPlay,
  Media_LoadLastStudy,
  Media_RollBack,
  Media_MiniPlaying
} from '../funName'

const media = {
  namespaced: true,
  state: {
    src: null, //视频源
    seekTime: null, //断点播放时间
    progressInfo: {}, //进度信息
    playingTime: 0, //当前播放时间
    playVideoId: "", //课件ID
    playVideoTime: "", //课件视频总时长
    course: {}, //课程信息,
    isUseMini: false, //是否使用迷你播放器
    miniHide: false, //迷你播放器是否收缩
    miniAutoPlay: false, //迷你播放器是否自动播放
    miniPlaying: false, //迷你播放器是否正在播放
    backData: null //当前状态的备份数据
  },
  mutations: {
    Media_SetSource(state, src) {
      Vue.set(state, "src", src);
    },
    Media_SetSeekTime(state, num) {
      Vue.set(state, "seekTime", num);
    },
    Media_SetProgress(state, data) {
      if (dsf.isUnDef(data)) {
        data = {};
      }
      Vue.set(state, "progressInfo", data);
    },
    Media_SetCourse(state, data) {
      if (dsf.isUnDef(data)) {
        data = {};
      }
      Vue.set(state, "course", data);
    },
    Media_UpdatePlayTime(state, data) {
      if (dsf.isUnDef(data)) {
        data = "00:00:00"
      }
      Vue.set(state, "playingTime", data);
    },
    Media_ChangeVideo(state, data) {
      Vue.set(state, "playVideoId", data.videoId);
      Vue.set(state, "playVideoTime", data.videoTime);
    },
    Media_UseMini(state, data) {
      Vue.set(state, 'isUseMini', data);
    },
    Media_MiniHide(state, data) {
      Vue.set(state, 'miniHide', data);
    },
    Meida_MiniAutoPlay(state, data) {
      Vue.set(state, 'miniAutoPlay', data);
    },
    Media_LoadLastStudy(state, data) {
      let ls = JSON.parse(localStorage.getItem('lastStudyInfo'));
      if (ls) {
        state.src = ls.src
        state.seekTime = ls.seekTime;
        state.progressInfo = ls.progressInfo;
        state.playingTime = ls.playingTime;
        state.course = ls.course;
        state.playVideoId = ls.playVideoId;
        state.playVideoTime = ls.playVideoTime;
        state.isUseMini = true;
        state.miniHide = true;
        state.miniPlaying = false;
        state.miniAutoPlay = false;
      } else {
        state.isUseMini = false;
        state.miniHide = false;
        state.miniAutoPlay = false;
        state.miniPlaying = false;
      }
    },
    Media_RollBack(state, data) {
      if (state.backData) {
        for (let k in state.backData) {
          if (k != 'backData') {
            Vue.set(state, k, state.backData[k]);
          }
        }
        Vue.set(state, 'miniAutoPlay', state.miniPlaying);
      }
    },
    Media_MiniPlaying(state, data) {
      Vue.set(state, 'miniPlaying', data);

    }
  },
  //actions是用于做异步请求处理数据
  // mutations,actions都是用于修改state中的数据，唯一不同在于，同步用mutations，异步用actions
  actions: {
    //通过课件上传视频文件ID获取到实际资源
    Media_GetSource(store, opts) {
      return new Promise((resolve, reject) => {
        if (opts.type == "audio" || opts.type == "video") {
          let videoUrl = "/slice/video/" + opts.fileId;
          dsf.http.get(videoUrl, {}, $$webRoot.nc).done((res) => {
            if (res.success && res.state == 20000) {
              store.commit('media/Media_SetSource', res.data);
              resolve(res.data);
            }
          }).error((error) => {
            reject(error)
            console.log(error)
          });
        } else if (opts.type == "text" || opts.type == "media" || opts.type == 'other') {
          let data = {
            sd: {
              relative: opts.fileId
            },
            cd: {
              relative: opts.fileId
            },
            hd: {
              relative: opts.fileId
            },
            mp3: {
              relative: opts.fileId
            }
          }
          store.commit('media/Media_SetSource', data);
          resolve(data);
        }
      });
    },
    //获取播放时长
    Media_GetProgress(store, opts) {
      let speedProUrl = "nc/mobile/tyzj/kcbf/queryKjXxjl";
      let params = {
        relid: opts.videoId,
        kcId: opts.kcId
      };
      return new Promise((resolve, reject) => {
        dsf.http.get(speedProUrl, params, $$webRoot.nc)
          .done(function (res) {
            if (res.success) {
              if (res.state == 20000) {
                let seekTime = 0
                if (opts.seekTime) {
                  seekTime = opts.seekTime;
                } else {
                  seekTime = formatSeek(res.data.timespan);
                }
                let curVideoSpeedPro = res.data;
                store.commit('media/Media_SetSeekTime', seekTime);
                store.commit('media/Media_SetProgress', curVideoSpeedPro);
                resolve(res);
              } else {
                store.commit('media/Media_SetSeekTime', 0);
                store.commit('media/Media_SetProgress', {});
                reject(res);
              }

            } else {
              reject(res);
            }
          })
          .error(function (error) {
            reject(error)
          })
          .always(function () { });
      })
    },
    //获取课件信息
    Media_SetCourse(store, opts) {
      return new Promise((resolve, reject) => {
        const courseUrl = "nc/mobile/tyzj/kcbf/getKcDetailInfo";
        const params = {
          kcid: opts.kcid,
          type: opts.type || 2, //1专题班 2课程
          dwid: opts.dwid
        };
        dsf.http.get(courseUrl, params, $$webRoot.nc).done((res) => {
          if (res.state == "20000") {
            let data = res.data;
            let course = {};
            course = data;
            store.commit('media/Media_SetCourse', course);
            store.commit('media/Media_ChangeVideo', {
              videoId: data.bfinfo.kjid,
              videoTime: data.bfinfo.kjsc
            })
            resolve(res);
          } else {
            store.commit('media/Media_SetCourse', null);
            reject(res);
          }
        }).error(function (err) {
          store.commit('media/Media_SetCourse', null);
          reject(err);
        })
      });
    },
    //提交学习进度
    Media_PostProgress(store, opts) {
      let speedProUrl = "nc/mobile/tyzj/kcbf/addXxjlByKczj";
      let s = dsf.date.secondsToTime(store.state.playingTime);
      s = s.hours + ":" + s.minutes + ":" + s.seconds;
      let params = {
        relid: store.state.playVideoId,
        bigtimespan: store.state.playVideoTime, //当前视频的总时长
        timespan: s,
        pass: store.state.progressInfo.pass || 0, //是否已经通过，
        sumtime: store.state.progressInfo.sumtime, //学习总时长
        everytime: opts.everytime || 10 //本次学习时长
      };
      // dsf.layer.toast("提交进度"+s)
      // window.localStorage.setItem("videoId-" + store.state.playVideoId, store.state.playingTime);
      // dsf.log(window.localStorage.getItem("videoId-"+store.state.playVideoId))
      return new Promise((resolve, reject) => {
        dsf.http.post(speedProUrl, params, $$webRoot.nc)
          .done(function (res) {
            //记录最后一次学习信息
            let obj = dsf.mix({}, store.state);
            delete obj.isUseMini;
            localStorage.setItem("lastStudyInfo", JSON.stringify(obj));
            console.log(localStorage.getItem("lastStudyInfo"))
            resolve(res);
          })
          .error(function (error) {
            // dsf.layer.toast("提交进度有问题"+s)
            reject(error);
          })
          .always(function () { });
      });


    },
    //获取课程信息、播放进度信息、
    Media_GetCourseAll({
      dispatch,
      commit,
      state
    }, opts) {
      return new Promise((resolve, reject) => {
        const params = {
          kcid: opts.kcid,
          type: opts.type || 2, //1专题班 2课程
          dwid: opts.dwid || ""
        };
        let q = queue();
        let videoId = state.playVideoId;
        //保存一下当前课程的信息，避免三个接口任何一步出现错误后可以回滚
        state.backData = dsf.mix(true, {}, state);
        //获取课程详情
        q.step((def, v) => {
          dispatch(Media_GetCourse, params)
            .then((res) => {
              console.log(res)
              def.resolve(res.data);
            }).catch((err) => {
              def.reject(err);
              console.log(err)
            });
        });
        //请求课程视频切片后的地址
        q.step((def, v) => {
          let item = v.bfinfo;
          let d, type, fileId;
          switch (item.kjlx_value) {
            case "1": //视频
              d = JSON.parse(item.spdz)[0];
              type = "video";
              fileId = d.id;
              break;
            case "2": //音频
              d = JSON.parse(item.ypdz)[0];
              type = "audio";
              fileId = d.id;
              break;
            case "3": //文稿
              d = JSON.parse(item.wgdz)[0];
              type = "text";
              fileId = "/preview/file?fileId=" + d.id;
              break;
            default: //富媒体、其他
              d = item.yddz
              type = item.kjlx_value == 4 ? "media" : "other";
              fileId = item.yddz;
              break;
          }
          dispatch('media/Media_GetSource', {
            fileId: fileId,
            type: type
          }).then((src) => {
            def.resolve();
          }).catch((err) => {
            def.reject(err);
          })
        });
        //获取课程的进度
        q.step((def, v) => {
          let params = {
            videoId: state.playVideoId,
            kcId: state.course.kcid,
            //判断课件和迷你播放器中播放的是否是统一个课件，如果是则将迷你播放器当前播放时间传入
            seekTime: videoId == state.playVideoId ? state.playingTime : ""
          }
          dispatch(Media_GetProgress, params).then(function () {
            def.resolve(v);
          }).catch(function (err) {
            def.reject(err);
          });
        });
        q.step((def) => {
          resolve({
            "courseData": state.course,
            "source": state.src,
            "progressInfo": state.progressInfo
          })
        });
        q.catch((err) => {
          reject(err);
        })
        q.exec();
      });
    }
  },
  getters: {}
}

function formatSeek(t) {
  if (!t) t = "00:00:00";
  t = t.split(":");
  var s = t[0] * 60 * 60 + t[1] * 60 + t[2] * 1;
  return s;
}

function getMimeType(extName) {
  let type = ""
  switch (extName) {
    case "mp3":
      type = 'audio/mpeg3';
      break;
    case "m3u8":
      type = 'application/x-mpegURL';
      break;
    case "mp4":
      type = "video/mp4";
      break;
    default:
      type = "";
      break;
  }
  return type;
}


export default media